import {ApolloProvider} from "@apollo/client"
import client from "api/apollo"
import {ActiveExamInfo} from "page/ExamsOnboard/index.js"
import React, {lazy, Suspense, useEffect, useMemo, useRef} from "react"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate
} from "react-router-dom"
import cn from "classnames"
import {useDispatch, useSelector} from "react-redux"
import useLocationRedirect from "hooks/useRoleRedirect"
import {checkUserLogined, fetchUserDetailsThunk} from "store/slices/user"
import {makeStyles} from "@mui/styles"
import {ThemeProvider, StyledEngineProvider} from "@mui/material/styles"
import {CircularProgress, CssBaseline} from "@mui/material"
import SignIn from "page/SignIn"
import UpdateUserAccountInfo from "page/UpdateUserAccountInfo"
import {
  ExaminationWindow,
  ExamsConfirmPrivacy,
  ExamSelectGroup,
  ExamsInfo,
  ExamsPaymentMethod,
  ExamsProctorVerifi,
  ExamsSelection,
  ExamsVerify,
  ExamsVoucher,
  FAQs,
  ForgotPassword,
  Results,
  ReviewTasks,
  ScoreTasks,
  SignUp,
  SmeAction,
  SmeExams
} from "page"

import {
  Alert,
  BackdropLoad,
  Footer,
  MenuAppBar,
  ProtectedRoute
} from "./components"
import {LINKS} from "constants/links"
import {USER_GROUP} from "constants/constants"
import {TasksList} from "page/TaskEvaluation/components"
import {createTheme} from "theme/defaultTheme"

const AdminPanel = lazy(() => import("page/AdminPanel/AdminPanel"))
const Dashboard = lazy(() => import("page/AdminPanel/components/Dashboard"))
const OrganProfile = lazy(
  () => import("page/AdminPanel/components/OrganProfile")
)
const Groups = lazy(() => import("page/AdminPanel/components/Groups"))
const Licenses = lazy(() => import("page/AdminPanel/components/Licences"))
const Vouchers = lazy(() => import("page/AdminPanel/components/Vouchers"))
const Proctors = lazy(() => import("page/AdminPanel/components/Proctors"))
const Settings = lazy(() => import("page/AdminPanel/components/Settings"))
const PrivacyPolicy = lazy(() => import("page/PrivacyPolicy"))
const TermsAndConditions = lazy(() => import("page/TermsAndConditions"))
const TestSentryIntegration = lazy(() => import("./components/TestSentryIntegration"))

const useStyles = makeStyles({
  wrapper: {
    position: "relative"
  },
  content: {
    marginTop: "72px",
    height: "calc(100vh - 132px)",
    overflowY: "auto"
  },
  contentOnly: {
    marginTop: 0,
    height: "100vh"
  }
})

const FallbackRouteComponent = () => {
  const {user, userGroups} = useSelector((state: any) => state.user)
  const isSME = userGroups.includes(USER_GROUP.SME)
  // const isADMIN = userGroups.includes(USER_GROUP.ADMIN)
  const location = useLocation()

  const toQuestionPage = useRef("")
  // check if we are going to go to question page
  const scoreItemRoute = useMatch("/sme/tasks/score/:examCode/:questionId")

  useEffect(() => {
    if (scoreItemRoute) {
      toQuestionPage.current = location.pathname
    }
  }, [location.pathname, scoreItemRoute])

  if (!user) return

  if (isSME) {
    /**
     * SME
     */
    // if we are going to go to question page
    if (toQuestionPage.current) {
      const temp = toQuestionPage.current
      toQuestionPage.current = ""
      return <Navigate to={temp} />
    }
    // else
    return <Navigate to={LINKS.smeExams} />
  }

  return <Navigate to={LINKS.examsSelection} />
}

export default function App() {
  const s = useStyles()
  const theme = createTheme()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const {user, loading, activeExamSession, isAuth} = useSelector(
    (state: any) => state.user
  )

  useLocationRedirect()

  const detectSessionLocation = useMemo(() => {
    const findExamWindowStr = location?.pathname.indexOf("/examination-window")
    return findExamWindowStr > 0
  }, [location?.pathname])

  const isAuthPage = useMemo(() => {
    return location?.pathname === "/"
  }, [location?.pathname])

  useEffect(() => {
    if (!!isAuth) {
      dispatch(fetchUserDetailsThunk())
    }
  }, [isAuth, detectSessionLocation])

  useEffect(() => {
    dispatch(checkUserLogined())
  }, [user])

  useEffect(() => {
    if (!detectSessionLocation && activeExamSession) {
      navigate(LINKS.activeExamInfo)
    }
  }, [activeExamSession, detectSessionLocation])

  if (loading) return <BackdropLoad open={loading} />

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <CssBaseline />
          <div className={s.wrapper}>
            {!detectSessionLocation && !isAuthPage && <MenuAppBar />}
            <div
              className={cn(s.content, {
                [s.contentOnly]: !!detectSessionLocation || isAuthPage
              })}
            >
              <Alert />
              <Suspense fallback={<CircularProgress color="inherit" />}>
                <Routes>
                  <Route
                    path={LINKS.activeExamInfo}
                    element={
                      <ProtectedRoute>
                        <ActiveExamInfo />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.examsSelection}
                    element={
                      <ProtectedRoute>
                        <ExamsSelection />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.examsSelectGroup}
                    element={
                      <ProtectedRoute>
                        <ExamSelectGroup />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.examsConfirmPrivacy}
                    element={
                      <ProtectedRoute>
                        <ExamsConfirmPrivacy />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.examsVoucher}
                    element={
                      <ProtectedRoute>
                        <ExamsVoucher />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.examsVerify}
                    element={
                      <ProtectedRoute>
                        <ExamsVerify />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.examsPaymentMethod}
                    element={
                      <ProtectedRoute>
                        <ExamsPaymentMethod />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.examsInfo}
                    element={
                      <ProtectedRoute>
                        <ExamsInfo />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.updateUserAccount}
                    element={
                      <ProtectedRoute>
                        <UpdateUserAccountInfo />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.examsProctorVerifi}
                    element={
                      <ProtectedRoute>
                        <ExamsProctorVerifi />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.smeSelectAction}
                    element={
                      <ProtectedRoute>
                        <SmeAction />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.smeExams}
                    element={
                      <ProtectedRoute>
                        <SmeExams />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.smeDynamicTasksList}
                    element={
                      <ProtectedRoute>
                        <TasksList />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.smeReviewTaskItem}
                    element={
                      <ProtectedRoute>
                        <ReviewTasks />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.smeScoreTaskItem}
                    element={
                      <ProtectedRoute>
                        <ScoreTasks />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={LINKS.results}
                    element={
                      <ProtectedRoute>
                        <Results />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${LINKS.examWindow}/:examCode`}
                    element={
                      <ProtectedRoute>
                        <ExaminationWindow />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${LINKS.examWindow}/:examCode/:sessionId`}
                    element={
                      <ProtectedRoute>
                        <ExaminationWindow />
                      </ProtectedRoute>
                    }
                  />

                  <Route path={LINKS.signUp} element={<SignUp />} />
                  {/*<Route path={LINKS.authWithSSO} element={<AuthWithSSO />} />*/}
                  {/* <Route
                    path={`${LINKS.examWindow}/:examCode/:sessionId`}
                    element={<ExaminationWindow/>}
                  /> */}
                  {/* <Route
                    path={`${LINKS.examWindow}/:examCode`}
                    element={<ExaminationWindow/>}
                  /> */}
                  {/* // */}
                  <Route
                    path={LINKS.adminPanelDashboard}
                    element={() => <AdminPanel children={<Dashboard />} />}
                  />
                  <Route
                    path={LINKS.adminPanelOrgan}
                    element={() => <AdminPanel children={<OrganProfile />} />}
                  />
                  <Route
                    path={LINKS.adminPanelExamGroup}
                    element={() => <AdminPanel children={<Groups />} />}
                  />
                  <Route
                    path={LINKS.adminPanelLicense}
                    element={() => <AdminPanel children={<Licenses />} />}
                  />
                  <Route
                    path={LINKS.adminPanelVouchers}
                    element={() => <AdminPanel children={<Vouchers />} />}
                  />
                  <Route
                    path={LINKS.adminPanelReports}
                    element={() => <AdminPanel children={<></>} />}
                  />
                  <Route
                    path={LINKS.adminPanelProctors}
                    element={() => <AdminPanel children={<Proctors />} />}
                  />
                  <Route
                    path={LINKS.adminPanelSettings}
                    element={() => <AdminPanel children={<Settings />} />}
                  />
                  {/* // */}
                  <Route
                    path={LINKS.forgotPassword}
                    element={<ForgotPassword />}
                  />
                  <Route
                    path={LINKS.termsAndCond}
                    element={<TermsAndConditions />}
                  />
                  <Route path={LINKS.faqs} element={<FAQs />} />
                  <Route
                    path={LINKS.privacyPolicy}
                    element={<PrivacyPolicy />}
                  />
                  {/* {getUserFirstContent()} */}
                  {/* <Route path="/" element={ <SignIn /> }/> */}
                  <Route
                    path="/test-sentry-integration"
                    element={<TestSentryIntegration />}
                  />
                  <Route
                    path="*"
                    element={!user ? <SignIn /> : <FallbackRouteComponent />}
                  />
                </Routes>
              </Suspense>
            </div>
            {!detectSessionLocation && <Footer />}
          </div>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
